/* src/components/Navbar/Navbar.module.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 20px 40px;
  }
  
  .logo a {
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    color: #ae395d; /* Maroon */
  }
  
  .navLinks {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .navLinks li a {
    text-decoration: none;
    color: #000000;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .navLinks li a:hover {
    color: #ae395d; /* Maroon on hover */
  }
  