/* src/pages/Home/Home.module.css */

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  font-family: 'Helvetica Neue', sans-serif;
  background-color: #ffffff;
  color: #333;
  max-width: 100vw;
}

/* HERO SECTION */
.hero {
  text-align: center;
  margin-bottom: 2rem;
  animation: fadeIn 1s ease;
}

.logo {
  margin-bottom: 1rem;
}

.logoImg {
  width: 100px;
  height: auto;
}

/* SELECTOR TITLE */
.selectorTitle {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #666;
}

/* SELECTORS */
.selectors {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 800px;
  animation: slideDown 0.6s ease;
}

.selectorGroup {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.selectorButton {
  background-color: #fff;
  border: 2px solid #ccc;
  padding: 0.75rem 1.2rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selectorButton:hover {
  background-color: #f0faff;
  transform: translateY(-2px);
}

.activeSelector {
  background-color: #ae395d;
  color: #fff;
  border-color: #ae395d;
  transform: translateY(-1px);
}

/* APP TABS */
.appTabs {
  display: flex;
  overflow-x: auto;
  margin-bottom: 2rem;
  border-bottom: 2px solid #ddd;
  animation: fadeIn 0.5s ease;
}

.hero h1 {
  font-size: 5rem;
  margin-bottom: 2rem;
}

.appTabButton {
  background-color: transparent;
  border: none;
  padding: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease, border-bottom 0.3s ease;
  position: relative;
  white-space: nowrap;
  font-size: 0.9rem;
  color: #555;
}

.appTabButton:hover {
  transform: translateY(-2px);
}

.activeAppTab {
  color: #ae395d;
  font-weight: bold;
  border-bottom: 3px solid #ae395d;
}

.appTabContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.appTabName {
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
}

.appTabDescription {
  font-size: 0.85rem;
  color: #555;
}

/* ACTIVE APP CONTENT */
.appContent {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-sizing: border-box;
  max-width: 100%;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade out/in for tab switching */
.fadeOut {
  animation: fadeOut 0.3s forwards;
}
.fadeIn {
  animation: fadeInContent 0.3s forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

@keyframes fadeInContent {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
