.container {
    display: flex;
    width: 100%;
    background-color: #f7f7f7;
    border-radius: 50px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

/* Left Sidebar (features and logo) */
.sidebar {
    width: 35%;
    padding: 30px 20px;
    border-right: 1px solid #ddd;
    background-color: #fff;
    color: #333;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.logo {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the logo and text horizontally */
    justify-content: center; /* Center the logo and text vertically */
    text-align: center; /* Center text */
    margin-bottom: 20px;
}

.logo {
    width: 100px;
    height: auto;
    margin-bottom: 10px; /* Adjust spacing between the logo and text */
}


.header h1 {
    margin-bottom: 10px;
    font-size: 28px;
    color: #000;
    text-align: center;
}

.header p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 2;
    text-align: center;
}

.sidebar p {
    margin-bottom: 15px;
    /* text-align: center; */
    line-height: 2;
}

.features {
    margin-bottom: 30px;
}

.features h2 {
    color: #000;
    font-size: 24px;
    margin-bottom: 10px;
}

.features p {
    font-size: 16px;
    line-height: 2;
}

.screenshots {
    margin-top: 30px;
    text-align: center;
}

.screenshot {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
}

/* Right Content Section (mainContent) */
.editorSection {
    width: 65%;
    padding: 30px 20px;
    background-color: #fafafa;
    color: #333;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.editorSection h2 {
    color: #000;
    font-size: 24px;
    margin-bottom: 15px;
}

.editorSection p {
    margin-bottom: 20px;
    line-height: 2;
}

.callToAction {
    margin-top: 30px;
    text-align: center;
}

.downloadButton {
    display: inline-block; /* Make it behave like a block-level element */
    padding: 10px 20px; /* Add padding for spacing inside the button */
    cursor: pointer;
    background-color: #FFA116; /* Accent color */
    color: #fff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    text-align: center; /* Center the text inside the button */
    transition: background-color 0.3s ease;
}

.downloadButton:hover {
    background-color: #e59400;
}


hr {
    width: 100%;
    border: none;
    height: 1px;
    background: #d0d0d0;
}

/* Footer */
.footer {
    text-align: center;
    font-size: 14px;
    color: #777;
    margin-top: 40px;
}

/* Disclaimer */
.disclaimer {
    margin: 30px 0px 30px 0px;
    text-align: center;
    font-size: 12px;
    color: #888;
}

.disclaimer a {
    color: #e59400;
    text-decoration: none;
}

.disclaimer a:hover {
    color: #e59400;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .container {
        flex-direction: column;
    }

    .sidebar,
    .editorSection {
        width: 100%;
    }

    .downloadButton {
        max-width: 100%;
    }
}
